import { Injectable } from '@angular/core';
import { CameraOptions } from '@ionic-native/camera/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { FileUploadOptions, FileTransferObject, FileTransfer } from '@ionic-native/file-transfer/ngx';
import { CONFIG } from '../config';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class CameraService {
    constructor(
      public camera: Camera,
      public transfer: FileTransfer,
      public storageService: StorageService) { }

    takePicture(sourceType: any) {
        const options: CameraOptions = {
            quality: 100,
            destinationType: this.camera.DestinationType.FILE_URI,
            sourceType: sourceType as any,
            saveToPhotoAlbum: false,
            correctOrientation: true
        };
        return this.camera.getPicture(options);
    }
    async uploadImage(img: any, path: any) {
        // Destination URL
        let url = CONFIG.SERVER_URL + path;
        // File for Upload
        const targetPath = img;
        const options: FileUploadOptions = {
            fileKey: 'image',
            chunkedMode: false,
            mimeType: 'multipart/form-data',
        };
        const fileTransfer: FileTransferObject = this.transfer.create();
        let session: any;
        await this.storageService.getCurrentSession().then((val: any) => {
            session = val;
        });
        if(session){
            const n = url.indexOf('?');
            if(n !== -1){
                url = url+'&access-token='+session.user.access_token;
            }else{
                url = url+'?access-token='+session.user.access_token;
            }
        }
        // Use the FileTransfer to upload the image
        return fileTransfer.upload(targetPath, url, options);
    }
}
