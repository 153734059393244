import { User } from "./user.model";
import { StructResponse } from "./struct-response.model";

export class Session {
    public token: string;
    public user: User;
    public date: string;
    constructor(data: StructResponse, password?){
        this.user = data.data;
        if(password){
            this.user.password = password;
            // this.token = btoa(this.user.email + ':' + password);
        }else{
            // this.token = btoa(this.user.email + ':' + this.user.password);
        }
        this.token = this.user.access_token;
        this.date = (new Date()).getTime().toString();
    }
}
